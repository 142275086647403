<template>
  <div id="order" class="grid">
    <div class="pg-header">
      <div class="pg-back-button">
        <button class="btn-primary" @click="back">Tillbaka</button>
      </div>
      <h2>Lägg till kommentar</h2>
    </div>
    <div v-if="myOrder" class="pg-form">
      <div class="pg-form-group">
        <label class="pg-label">Ordernummer: {{ myOrder.order_number }}</label>
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Status: {{ myOrder.order_status_name }}</label>
      </div>
      <div class="pg-form-group">
        <label class="pg-label">Kommentar</label>
        <textarea v-model="myOrder.comments" class="pg-input"></textarea>
      </div>
      <div class="pg-form-group">
        <button class="btn-primary" @click="saveMyOrder">Spara</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { format } from 'date-fns';

export default {
  name: 'WorkOrderComments',
  props: {
    orderId: {
      type: Number,
      default: 0
    }
  },

  data() {
    return {
      search: '',
      showError: false,
      error: '',
      myOrderStatus: [],
      myOrder: {
        order_id: 0,
        order_number: '',
        prio_number: 0,
        customer_name: '',
        delivery_date: new Date(),
        changed_delivery_date: '',
        color: '',
        palette_amount: 1,
        order_status_id: 1,
        comments: '',
        delivery_id: '',
        order_link: '',
        email_address: '',
        email_sent_date: null
      }
    };
  },
  computed: {
    /*
    isLoggedIn: function () {
      return this.$store.getters.isLoggedIn;
    },
    isAdmin: function () {
      return this.$store.getters.isAdmin;
    },
    isWorker: function () {
      return this.$store.getters.isWorker;
    },
    isSeller: function () {
      return this.$store.getters.isSeller;
    },
    */
  },
  created() {},
  async mounted() {
    if (this.orderId > 0) {
      await this.fetchMyOrder();
    }
  },
  methods: {
    ...mapActions({
      fetchOrder: 'orders/fetchOrder',
      updateOrder: 'orders/updateOrder'
    }),
    back: function () {
      this.$router.replace({
        name: 'WorkOrders',
        params: {
          addUpdateOK: false,
          hasAddUpdate: false,
          action: ''
        }
      });
    },
    async fetchMyOrder() {
      try {
        this.myOrder = [];
        let data = {
          order_id: this.orderId
        };
        this.myOrder = await this.fetchOrder(data);
      } catch (error) {
        //  console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        // console.log("We do cleanup here");
      }
    },
    async saveMyOrder() {
      let response = null;

      try {
        if (this.myOrder.changed_delivery_date == '') {
          this.myOrder.changed_delivery_date = '1970-01-01';
        }

        let data = {
          order_id: this.myOrder.order_id,
          order_number: this.myOrder.order_number,
          prio_number: this.myOrder.prio_number,
          customer_name: this.myOrder.customer_name,
          delivery_date: format(new Date(this.myOrder.delivery_date), 'yyyy-MM-dd'),
          changed_delivery_date: format(new Date(this.myOrder.changed_delivery_date), 'yyyy-MM-dd'),
          color: this.myOrder.color,
          palette_amount: this.myOrder.palette_amount,
          comments: this.myOrder.comments,
          delivery_id: this.myOrder.delivery_id,
          order_status_id: this.myOrder.order_status_id
        };

        response = await this.updateOrder(data);
      } catch (error) {
        // console.log(error);
        // TODO : FIX ERROR LOG
        if (error.response) {
          if (error.response.status === 401) {
            try {
              await this.$store.dispatch('logout');
            } catch (err) {
              console.error(err);
              // TODO : FIX ERROR LOG
            } finally {
              this.$router.replace({
                name: 'Login'
              });
            }
          }
        }
      } finally {
        let addUpdateOK = false;

        if (response && response.success == 1) {
          addUpdateOK = true;

          this.$router.replace({
            name: 'WorkOrders',
            params: {
              addUpdateOK: addUpdateOK,
              hasAddUpdate: true,
              action: 'SAVE'
            }
          });
        }
      }
    }
  }
};
</script>

<style lang="scss" scoped>
textarea {
  resize: none;
  height: 300px;
}
</style>
